import logo from "./logo.svg";
import "./App.css";

import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import WebSocket from "ws";

class AccountTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { price: 0, updated: false};
    let urlParams = new URLSearchParams(window.location.search);
    this.hashList = [];
    if (urlParams.has("hashs")) {
      this.hashList = urlParams.get("hashs").split(",");
    }
    this.price = 0;
    this.balances = [];
    this.updateAccountBalances();
    // this.fetchPrice();

    this.updateAccountBalances = this.updateAccountBalances.bind(this);

  }

  componentDidMount() {
    fetch('https://api.binance.com/api/v3/ticker/price?symbol=XLMEUR')
    .then((response) => response.json())
    .then(priceData => {
        this.setState({ price: parseFloat(priceData.price) });
    });
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async tick() {
    let price = await this.fetchPrice();
    this.setState({
      date: price
    });
  }

  fetchPrice() {
    fetch(
      "https://api.binance.com/api/v3/ticker/price?symbol=XLMEUR"
    ).then((response) => {
      if (!response.ok) {
        return false;
      } else {
        const json = response.json();
        this.setState({
          date: parseFloat(json.price)
        });
        return parseFloat(json.price);
      }
    });
  }

  async updateAccountBalances() {
    let balancesList = {};
    let balancesSum = 0;
    for (let hash of this.hashList) {
      const response = await fetch(
        "https://horizon.stellar.org/accounts/" + hash
      );
      const json = await response.json();
      let balances = {};
      for (let balance of json.balances) {
        if (balance.asset_type === "native") {
          balances["XLM"] = parseFloat(balance.balance);
          balancesSum += parseFloat(balance.balance);
        } else {
          balances[balance.asset_code] = parseFloat(balance.balance);
          balancesSum += parseFloat(balance.balance);
        }
        if (!Object.keys(balances).includes("yXLM")) {
          balances["yXLM"] = 0;
        }
      }
      balancesList[hash] = balances;
    }
    balancesList["price"] = this.state.price;
    balancesList["time"] = new Date();
    balancesList["sum"] = balancesSum;
    this.balances.push(balancesList);
    this.setState({ updated: true });
  }

  render() {
    let optionsEUR = {
      style: "currency",
      currency: "EUR",
    };
    let optionsXLM = {
      style: "currency",
      currency: "XLM",
    };
    let optionsXChange = {
      style: "decimal",
      maximumFractionDigits: 4
    };
    return (
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Button
                    onClick={this.updateAccountBalances}
                    variant="outlined"
                  >
                    Load
                  </Button>
                </TableCell>
                {this.hashList.map((hash) => {
                  return (
                    <TableCell align="center" colSpan={2}>
                      <Typography variant="h6">
                        {hash.slice(0, 4)}...{hash.slice(28, 32)}
                      </Typography>
                    </TableCell>
                  );
                })}
                <TableCell align="center">
                  <Typography variant="body">
                    {this.state.price.toLocaleString("de-DE", optionsXChange)} XLM/EUR
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  
                <Typography variant="body2" fontWeight={600}>Zeit</Typography>
                </TableCell>
                {this.hashList.map((hash) => {
                  return (
                    <>
                      <TableCell align="center">
                        <Typography variant="body2" fontWeight={600}>XLM</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2" fontWeight={600}>yXLM</Typography>
                      </TableCell>
                    </>
                  );
                })}
                <TableCell align="center"><Typography variant="body2" fontWeight={600}>Summe</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.balances.map((balances) => {
                return (
                  <>
                    <TableRow>
                      <TableCell align="center" rowSpan={3}>
                        <Typography variant="body2">
                          {balances["time"].toLocaleTimeString()}
                        </Typography>
                        <Typography variant="caption">
                          {balances["time"].toLocaleDateString()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {this.hashList.map((hash) => {
                        return (
                          <>
                            <TableCell align="center">
                              <Typography variant="body2">
                                {(
                                  balances[hash]["XLM"] * balances["price"]
                                ).toLocaleString("de-DE", optionsEUR)}
                              </Typography>
                              <Typography variant="caption">
                                {balances[hash]["XLM"].toLocaleString(
                                  "de-DE",
                                  optionsXLM
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="body2">
                                {(
                                  balances[hash]["yXLM"] * balances["price"]
                                ).toLocaleString("de-DE", optionsEUR)}
                              </Typography>
                              <Typography variant="caption">
                                {balances[hash]["yXLM"].toLocaleString(
                                  "de-DE",
                                  optionsXLM
                                )}
                              </Typography>
                            </TableCell>
                          </>
                        );
                      })}
                      <TableCell align="center" rowSpan={3}>
                        <Typography variant="body2">
                          {(balances["sum"] * balances["price"]).toLocaleString(
                            "de-DE",
                            optionsEUR
                          )}</Typography>
                        <Typography variant="caption" display="block" >
                          {balances["sum"].toLocaleString("de-DE", optionsXLM)}
                        </Typography>
                        <Typography variant="caption">
                          {balances["price"].toLocaleString("de-DE", optionsXChange)} XLM/EUR
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {this.hashList.map((hash) => {
                        return (
                          <>
                            <TableCell align="center" colSpan={2}>
                              <Typography variant="body2">
                                {(
                                  (balances[hash]["XLM"] +
                                    balances[hash]["yXLM"]) *
                                  balances["price"]
                                ).toLocaleString("de-DE", optionsEUR)}
                              </Typography>
                              <Typography variant="caption">
                                {(
                                  balances[hash]["XLM"] + balances[hash]["yXLM"]
                                ).toLocaleString("de-DE", optionsXLM)}
                              </Typography>
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                    <TableRow></TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <AccountTable />
      </header>
    </div>
  );
}

export default App;
